'use client'

import { useRouter } from 'next/navigation'
import { useEffect, useRef } from 'react'

import Textarea from 'react-textarea-autosize'

import { useEnterSubmit } from '@/lib/hooks/use-enter-submit'
import { cn } from '@/lib/utils'
import { Button, buttonVariants } from '@/components/ui/button'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from '@/components/ui/tooltip'
import { IconArrowElbow, IconHome } from '@/components/ui/icons'
import { useTranslation } from 'react-i18next'
import { PrefetchKind } from 'next/dist/client/components/router-reducer/router-reducer-types'
import { UseChatHelpers } from '@aigxion/isdk/react'

export interface PromptProps
  extends Pick<UseChatHelpers, 'input' | 'setInput'> {
  onSubmit: (value: string) => void;
  isLoading: boolean;
  isWaitingForResponse?: boolean; // 添加这个属性
}


export function PromptForm({
  onSubmit,
  input,
  setInput,
  isLoading,
  isWaitingForResponse // 从useISDK返回的状态
}: PromptProps) {
  const router = useRouter()
  const { t, i18n } = useTranslation();

  const { formRef, onKeyDown } = useEnterSubmit()
  const inputRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])



  const placeholderText = t("describe_your_intent");
  //console.log("....PromotForm....Current language:", i18n.language); 
  //console.log("....PromotForm....Translation for 'describe_your_intent':", placeholderText);
  return (
    <form
      onSubmit={async e => {
        e.preventDefault()
        console.log('..................===> Before submission, isWaitingForResponse:', isWaitingForResponse);
        if (!input?.trim() || isLoading) {
          return
        }
        setInput('')
        await onSubmit(input)
        console.log('..................===> After submission, isWaitingForResponse:', isWaitingForResponse);
      }}
      ref={formRef}
    >


      <div className="relative flex w-full items-center px-2 sm:rounded-full sm:border-none sm:px-0.5 bg-[#f5f5f5]">
        <Textarea
          ref={inputRef}
          tabIndex={0}
          onKeyDown={onKeyDown}
          rows={1}
          value={input}
          onChange={e => setInput(e.target.value)}
          placeholder={placeholderText}
          spellCheck={false}
          className="min-h-[40px] max-h-[120px] w-full border-none resize-none bg-transparent px-0.5 py-2 focus-within:outline-none sm:text-sm placeholder:text-[#CCCCCC]"
          style={{
            borderRadius: '20px',
            overflowY: 'auto',
          }}
        />
        <div className="ml-4 flex items-center">
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                className="bg-[#555] h-8 w-16 sm:rounded-full text-white flex justify-center items-center disabled:bg-[#666666] disabled:opacity-100 text-[1rem]"
                type="submit"
                disabled={isLoading || !input?.trim()}
              >
                <span>{t("chat.send")}</span>
              </Button>
            </TooltipTrigger>
            <TooltipContent>{t("chat.send_message")}</TooltipContent>
          </Tooltip>
        </div>
      </div>



    </form>
  )
}



export function PromptForm2({
  onSubmit,
  input,
  setInput,
  isLoading
}: PromptProps) {
  const router = useRouter()
  const { t, i18n } = useTranslation();

  const { formRef, onKeyDown } = useEnterSubmit()
  const inputRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])



  const placeholderText = t("describe_your_intent");
  console.log("....PromotForm....Current language:", i18n.language); // 打印当前语言
  console.log("....PromotForm....Translation for 'describe_your_intent':", placeholderText); // 打印 t 函数的返回值



  return (
    <form
      onSubmit={async e => {
        e.preventDefault()
        if (!input?.trim() || isLoading) {
          return
        }
        setInput('')
        await onSubmit(input)
      }}
      ref={formRef}
    >

      <div className="relative flex w-full grow flex-col overflow-hidden px-8 sm:rounded-full sm:border-none sm:px-4 bg-[#b2b2b233]" >
        <Tooltip>
          <TooltipTrigger asChild>
            {/* <Button
              onClick={() => {
                router.push(`/`, { shallow: false })
              }}
              className={cn(
                buttonVariants({ size: 'sm', variant: 'secondary' }),
                'absolute left-0 top-4 h-8 w-8 rounded-full border p-0 sm:left-4'
              )}
            >
              <IconHome />
              <span className="sr-only">New Chat</span>
            </Button> */}
          </TooltipTrigger>
          <TooltipContent>New Chat</TooltipContent>
        </Tooltip>
        <Textarea
          ref={inputRef}
          tabIndex={0}
          onKeyDown={onKeyDown}
          rows={1}
          value={input}
          onChange={e => setInput(e.target.value)}
          //placeholder={t("describe_your_intent")}
          placeholder={placeholderText}
          spellCheck={false}
          className="min-h-[50px] w-full border-none resize-none bg-transparent px-0 py-[1rem] focus-within:outline-none sm:text-sm placeholder:text-[#666666]"
        />
        <div className="absolute right-0 top-1 sm:right-2 ">
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                className="bg-baclbtn h-11 sm:rounded-full px-6 disabled:bg-[#666666] disabled:opacity-100 text-[1.125rem]"
                type="submit"
                // size="icon"
                disabled={isLoading || !input?.trim()}
              >
                {/* <IconArrowElbow className="fill-white" /> */}
                <span >{t("chat.send")}</span>
              </Button>
            </TooltipTrigger>
            <TooltipContent>{t("chat.send_message")}</TooltipContent>
          </Tooltip>
        </div>
      </div>



    </form>
  )
}


export interface PromptIndexProps {
  onSubmit?: (value: string) => void;
  isLoading?: boolean;
  id: string;
  isWaitingForResponse?: boolean; // 添加这个属性
}


export function PromptForm_bak({
  onSubmit,
  input,
  setInput,
  isLoading
}: PromptProps) {
  const router = useRouter()
  const { formRef, onKeyDown } = useEnterSubmit()
  const inputRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  return (
    <form
      onSubmit={async e => {
        e.preventDefault()
        if (!input?.trim() || isLoading) {
          return
        }
        setInput('')
        await onSubmit(input)
      }}
      ref={formRef}
    >

      <div className="relative flex w-full grow flex-col overflow-hidden px-8 sm:rounded-md sm:border sm:px-12">
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              onClick={() => {
                router.push(`/`, {
                  // shallow: false 
                })
              }}
              className={cn(
                buttonVariants({ size: 'sm', variant: 'secondary' }),
                'absolute left-0 top-4 h-8 w-8 rounded-full border p-0 sm:left-4'
              )}
            >
              <IconHome />
              <span className="sr-only">New Chat</span>
            </Button>
          </TooltipTrigger>
          <TooltipContent>New Chat</TooltipContent>
        </Tooltip>
        <Textarea
          ref={inputRef}
          tabIndex={0}
          onKeyDown={onKeyDown}
          rows={1}
          value={input}
          onChange={e => setInput(e.target.value)}
          placeholder="Send a message."
          spellCheck={false}
          className="min-h-[60px] w-full resize-none bg-transparent px-4 py-[1.3rem] focus-within:outline-none sm:text-sm"
        />
        <div className="absolute right-0 top-4 sm:right-4">
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                type="submit"
                size="icon"
                disabled={isLoading || !input?.trim()}
              >
                <IconArrowElbow className="fill-white" />
                <span className="sr-only">Send message</span>
              </Button>
            </TooltipTrigger>
            <TooltipContent>Send message</TooltipContent>
          </Tooltip>
        </div>
      </div>
    </form>
  )
}
